<template>
  <div class="replace-form">
    <div v-for="item in forms">
      <p>{{ item.name }}</p>
      <a-input v-model="item.value"></a-input>
    </div>
  </div>
</template>

<script>
export default ({
  props: {
    formsText: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed:{
    forms:{
      get(){
        return this.formsText
      },
      set(newValue){
        this.$emit('update:formsText', newValue)
      }
    }

  }

})
</script>


<style scoped lang="scss">
.replace-form {
  min-height: 320px;
}
</style>